import Vue from "vue"
import vueRouter from "@/router/index.js"
import {Toast} from "vant";
import axios from "axios";
import qs from "qs";
import {getCookie} from "@/utils/storage.js";

axios.defaults.transformRequest = [
    function (data) {
        return qs.stringify(data);
    }
];

// 路由拦截
axios.interceptors.request.use(function (config) {
    config.headers["Authorization"] = `Bearer ${getCookie("token")}`;
    // 如果不是商品接口/订单列表才给全局loading
    if (config.method == "get") {
        if (config.url.indexOf("/goods/list") == -1 && config.url.indexOf("/orders") == -1 && config.url.indexOf("/customers") == -1) {
            Vue.$loading.show()
        }
    } else {
        Vue.$loading.show()
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


axios.interceptors.response.use(
    response => {
        Vue.$loading.hide()
        return response;
    },
    error => {
        if (error.response.data.code === -2) {
            Toast.fail({
                message: "请重新登录",
                onClose() {
                    vueRouter.push({name: "Login"})
                }
            });

        }
        Vue.$loading.hide()
        return Promise.reject(error.response.data)
    });

// 请求封装
export function post(url, params, callback) {
    axios({
        method: "post",
        url: url,
        data: params,
    }).then(res => {
        callback(res);
    });
}

export function del(url, params, callback) {
    axios({
        method: "delete",
        url: url,
        params: params,
    }).then(res => {
        callback(res);
    });
}

export function get(url, params, callback) {
    axios({
        method: "get",
        url: url,
        params: params,
    }).then(res => {
        callback(res);
    });
}