//http
import { del, get, post } from "./httpEngine.js";

// 接口地址
let kUrlPrefix = "https://dgsc.demo.qoomoe.com/api"; // 正式环境
// let kUrlPrefix = "http://cdrztest.demo.qoomoe.com/api" // 测试环境

// 登录
export function LOGIN(params, callback) {
  post(kUrlPrefix + "/login", params, function(res) {
    callback(res);
  });
}

// 登出
export function LOGOUT(params, callback) {
  get(kUrlPrefix + "/logout", params, function(res) {
    callback(res);
  });
}

// 获取banners
export function BANNERS(params, callback) {
  get(kUrlPrefix + "/banners", params, function(res) {
    callback(res);
  });
}

// 商品列表
export function GOODS_LIST(params, callback) {
  get(kUrlPrefix + "/goods/list", params, function(res) {
    callback(res);
  });
}

// 商品分类列表
export function GOODS_GROUP(params, callback) {
  get(kUrlPrefix + "/goods/group", params, function(res) {
    callback(res);
  });
}

// 商品详情
export function GOODS_DETAIL(params, callback) {
  get(kUrlPrefix + "/goods/details", params, function(res) {
    callback(res);
  });
}

// 创建订单
export function CREATE_ORDERS(params, callback) {
  post(kUrlPrefix + "/orders", params, function(res) {
    callback(res);
  });
}

// 订单列表
export function GET_ORDERS(params, callback) {
  get(kUrlPrefix + "/orders", params, function(res) {
    callback(res);
  });
}

// 添加购物车
export function ADD_CARTS(params, callback) {
  post(kUrlPrefix + "/carts", params, function(res) {
    callback(res);
  });
}

// 获取购物车
export function GET_CARTS(params, callback) {
  get(kUrlPrefix + "/carts", params, function(res) {
    callback(res);
  });
}

// 购物车删除
export function DEL_CARTS(params, callback) {
  del(kUrlPrefix + "/carts", params, function(res) {
    callback(res);
  });
}

// 获取顾客列表
export function GET_CUSTOMERS(params, callback) {
  get(kUrlPrefix + "/customers", params, function(res) {
    callback(res);
  });
}

// 新增顾客
export function ADD_CUSTOMERS(params, callback) {
  post(kUrlPrefix + "/customers", params, function(res) {
    callback(res);
  });
}

// 删除顾客
export function DEL_CUSTOMERS(params, callback) {
  del(kUrlPrefix + "/customers", params, function(res) {
    callback(res);
  });
}

// 修改密码
export function RESET_PASSWORD(params, callback) {
  post(kUrlPrefix + "/resetPassword", params, function(res) {
    callback(res);
  });
}
