export default {
    inject: ["app"],
    data() {
        return {

        };
    },
    mounted() {
    },
    methods: {

    }
}